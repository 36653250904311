import React from "react"

type Props = {
  className?: string
  onClick: () => void
  label: string
}

function ActiveFilter({ onClick, label }: Props) {
  return (
    <div className="mr-2 float-left flex gap-3 items-center text-sm text-font-1 font-medium bg-gray-20 py-2 px-3 rounded-full">
      <span>{label}</span>
      <button onClick={onClick}>
        <img
          src="/images/general/close.svg"
          alt=""
          className="w-[14px]"
          decoding="async"
        />
      </button>
    </div>
  )
}

export default ActiveFilter
