import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
  stroke?: string
}

Filter.defaultProps = {
  stroke: "#211E29",
}

function Filter(props: Props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 3.5L5.83333 7.58333V12.25H8.16667V7.58333L12.25 3.5V1.75H1.75V3.5Z"
        stroke={props.stroke}
      />
    </svg>
  )
}

export default Filter
