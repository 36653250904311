import React from "react"
import { graphql } from "gatsby"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import CasinoCollection from "~/models/casino-collection"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import CasinoToplistBlock from "~/ui/compositions/casino/toplist"
import CardGridBlock from "~/ui/compositions/generic/card-grid"
import ChecklistBlock from "~/ui/compositions/generic/checklist"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import CasinoListBlock from "~/ui/compositions/casino/list"

import {
  CasinoListQuery,
  ContentfulPage,
  ContentfulCasinoCollection,
} from "../../../types/graphql-types"

type Props = {
  data: CasinoListQuery
}

function CasinoListPage({ data }: Props) {
  const Analytics = Loadable(() => import("../helpers/analytics"))

  const page = new Page(data.contentfulPage as ContentfulPage)
  const casinoCollections = data.allContentfulCasinoCollection.nodes.map(
    collection => {
      return new CasinoCollection(collection as ContentfulCasinoCollection)
    }
  )

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />

        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && (
              <React.Fragment>
              <PageCoverBlock color="blue" {...block.props} />
              <div className="mb-2 mt-2">
                <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                  <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                </div>
              </div>
              </React.Fragment>
            )}
            {block.name == "CasinoList" && <CasinoListBlock {...block.props} />}
            {block.name == "CasinoToplist" && (
              <CasinoToplistBlock
                collection={casinoCollections.find(
                  collection => collection.type == block.props.type
                )}
                {...block.props}
              />
            )}
            {block.name == "CardGrid" && <CardGridBlock {...block.props} />}
            {block.name == "Checklist" && <ChecklistBlock {...block.props} />}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default CasinoListPage

export const query = graphql`
  query CasinoList {
    contentfulPage(url: { pathname: { eq: "/casinos/" } }) {
      ...PageFields
    }
    allContentfulCasinoCollection(
      filter: { type: { in: ["most-trusted", "fastest-payout"] } }
    ) {
      nodes {
        ...CasinoCollectionFields
      }
    }
  }
`
