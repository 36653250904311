import React from "react"
import cx from "classnames"

import Arrow from "~/ui/identity/icon/arrow"

type Props = {
  label?: string
  children: any
  className?: string
  value?: any
  name: string
  onChange: React.ChangeEventHandler<HTMLSelectElement>
}

function Select(props: Props) {
  const { label, children, name, value, onChange, className } = props

  return (
    <div className={cx("w-full", className)}>
      {label && (
        <label
          className="block mb-3 text-lg font-medium text-font-1"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <select
          className="bg-white appearance-none cursor-pointer w-full p-4 block py-[10px] px-4 pr-10 text-font-1 rounded-md border border-gray-30"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
        >
          {children}
        </select>
        <div className="pointer-events-none absolute right-4 top-1/2 transform -translate-y-1/2">
          <Arrow width={18} />
        </div>
      </div>
    </div>
  )
}

export default Select
