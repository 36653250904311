import React from "react"
import cx from "classnames"

type FilterProps = {
  onClick: () => void
  label: string
  active: boolean
  image: string
  alt: string
}

function Filter({ label, onClick, active, image, alt }: FilterProps) {
  return (
    <button
      onClick={onClick}
      className={cx(
        "flex-1 justify-center flex gap-2 text-sm text-white py-2 px-3 rounded-[6px] items-center",
        {
          "bg-blue-600 text-white": active,
        }
      )}
    >
      <img
        src={`/images/general/${image}.svg`}
        alt={alt}
        className="w-[20px]"
        decoding="async"
      />
      <span>{label}</span>
    </button>
  )
}

export default Filter
