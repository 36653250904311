import React from "react"
import cx from "classnames"

import TitledSection from "~/ui/layout/titled-section"
import LocalImage from "~/ui/elements/image/local"
import sanitize from "~/helpers/sanitize"

type Props = {
  title: string
  description: string
  items: CardProps[]
  background: "orange" | "white"
  grid: 4 | 3 | 2
  mandala?: boolean
}

CardGrid.defaultProps = {
  background: "orange",
  grid: 3,
}

function CardGrid({
  title,
  description,
  items,
  background,
  grid,
  mandala,
}: Props) {
  return (
    <TitledSection
      title={title}
      description={description}
      background={background}
      mandala={mandala}
    >
      <div
        className={cx("grid gap-4 lg:gap-6", {
          "grid-cols-1 md:grid-cols-2": grid == 2,
          "grid-cols-1 md:grid-cols-3": grid == 3,
          "grid-cols-1 md:grid-cols-4": grid == 4,
        })}
      >
        {items.map((item, index) => {
          return (
            <Card
              key={index}
              image={item.image}
              title={item.title}
              body={item.body}
              className="shadow-card"
              alt={item.alt}
            />
          )
        })}
      </div>
    </TitledSection>
  )
}

type CardProps = {
  image: string
  title: string
  body: string
  className?: string
  alt: string
}

function Card({ image, title, body, className, alt }: CardProps) {
  return (
    <div
      className={cx(
        "flex items-start gap-4 md:block p-3 lg:p-4 bg-white rounded-lg",
        className
      )}
    >
      <img
        src={`/images/${image}`}
        alt={title || alt}
        className="shrink-0 block mb-2 w-[50px] lg:w-[56px]"
        decoding="async"
      />

      <div>
        <h3 className="mb-2 font-bold leading-6 text-lg lg:text-2xl lg:leading-8 text-font-1">
          {title}
        </h3>
        <p
          className="text-base text-font-2"
          dangerouslySetInnerHTML={sanitize(body)}
        ></p>
      </div>
    </div>
  )
}

export default CardGrid
