import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { words } from "lodash"

import CasinoCollection from "~/models/casino-collection"
import Casino from "~/models/casino"
import Provider from "~/models/provider"
import CasinoCard from "~/ui/components/casino/card"
import ActiveFilter from "~/ui/components/generic/active-filter"
import PrimaryFilter from "~/ui/components/generic/filter"
import Filter from "~/ui/identity/icon/filter"
import Close from "~/ui/identity/icon/close"
import Select from "~/ui/elements/form/select"
import Search from "~/ui/elements/form/search"

import {
  ArcadeProvider,
  ContentfulCasino,
} from "../../../../types/graphql-types"

type Filter = {
  game: string
  payment: string
  provider: string
  rating: string
}

function CasinoList() {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search) as any
  const params = Object.fromEntries(urlSearchParams.entries())

  const [primaryFilter, setPrimaryFilter] = useState("popular")

  const { allContentfulCasino, allArcadeProvider, recommended, toplist } =
    useStaticQuery(
      graphql`
        query CasinoExplorer {
          allContentfulCasino(filter: { rating: { gt: 1 } }) {
            nodes {
              ...CasinoFields
            }
          }
          allArcadeProvider {
            nodes {
              name
            }
          }
          recommended: contentfulCasinoCollection(type: { eq: "recommended" }) {
            ...CasinoCollectionFields
          }
          toplist: contentfulCasinoCollection(type: { eq: "toplist" }) {
            ...CasinoCollectionFields
          }
        }
      `
    )

  const paymentMethods = ["Visa", "Mastercard", "Skrill", "UPI", "Paytm"]
  const ratingsOptions = [4, 3, 2]

  const [filtersOpened, setFiltersOpened] = useState(false)

  const [searchTerms, setSearchTerms] = useState("")
  const [filter, setFilter] = useState({
    payment: "all",
    provider: "all",
    rating: 0,
  })

  const filterContainer = React.useRef(null)

  const [payment, setPayment] = useState("all")
  const [provider, setProvider] = useState("all")
  const [rating, setRating] = useState(0)

  const toplistCasinos = new CasinoCollection(toplist)
  const recommendations = new CasinoCollection(recommended)

  useEffect(() => {
    if (params.q) {
      setSearchTerms(params.q)
    }
  }, [])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    closeFilters()
    e.preventDefault()

    setFilter({
      payment,
      provider,
      rating: rating,
    })
  }

  const toggleFilters = () => {
    if (filtersOpened) {
      closeFilters()
    } else {
      openFilters()
    }
  }

  const onClickOutside = (e: any) => {
    if (
      filterContainer.current &&
      !(filterContainer.current as any).contains(e.target)
    ) {
      closeFilters()
    }
  }

  const openFilters = () => {
    setFiltersOpened(true)
    document.addEventListener("mousedown", onClickOutside)
  }

  const closeFilters = () => {
    setFiltersOpened(false)
    document.removeEventListener("mousedown", onClickOutside)
  }

  const providers = allArcadeProvider.nodes.map(
    (provider: ArcadeProvider) => new Provider(provider)
  )

  const casinos = allContentfulCasino.nodes
    .map((casino: ContentfulCasino) => {
      return new Casino(casino)
    })
    .filter((casino: Casino) => {
      switch (primaryFilter) {
        case "recommended":
          return !!recommendations.casinos.find(c => c.slug == casino.slug)
        case "Slot Machine":
          return casino.availableGames.indexOf("Slot Machine") !== -1
        case "Roulette":
          return casino.availableGames.indexOf("Roulette") !== -1
        default:
          return true
      }
    })
    .filter(
      (casino: Casino) =>
        (filter.payment && filter.payment != "all"
          ? casino.paymentMethods.indexOf(filter.payment) !== -1
          : true) &&
        (filter.provider && filter.provider != "all"
          ? casino.gameProviders.indexOf(filter.provider) !== -1
          : true) &&
        (filter.rating ? casino.rating > filter.rating : true)
    )
    .map((casino: Casino) => {
      const matches = words(casino.name.toLowerCase()).reduce(
        (acc: any, node: any) => {
          const matches = words(searchTerms.toLowerCase()).reduce(
            (_acc: any, _node: any) => {
              if (node.indexOf(_node) != -1) {
                _acc.push(_node)
              }
              return _acc
            },
            []
          )

          return acc.concat(matches)
        },
        []
      )

      return [casino, matches.length]
    })
    .sort((a: [Casino, number], b: [Casino, number]) => {
      return b[1] - a[1]
    })
    .filter((node: [Casino, number]) => !searchTerms || node[1] > 0)
    .map((node: [Casino, number]) => node[0])

  return (
    <div>
      <section className="overflow-scroll scrollbar-hidden  max-w-screen-xl mx-auto pb-6 pt-10 md:pt-16 px-4 md:px-10 xl:px-20">
        <div className="min-w-[640px] p-1 bg-font-1 rounded-lg flex justify-between">
          <PrimaryFilter
            onClick={() => setPrimaryFilter("popular")}
            image="filter-popular"
            alt="Free Casino Games"
            active={primaryFilter == "popular"}
            label="Most Popular"
          />
          <PrimaryFilter
            onClick={() => setPrimaryFilter("recommended")}
            image="filter-recommended"
            alt="Free Casino Games"
            active={primaryFilter == "recommended"}
            label="Recommended"
          />
          <PrimaryFilter
            onClick={() => setPrimaryFilter("Slot Machine")}
            image="filter-slots"
            alt="Slots"
            active={primaryFilter == "Slot Machine"}
            label="Slots"
          />
          <PrimaryFilter
            onClick={() => setPrimaryFilter("Roulette")}
            image="filter-roulette"
            alt="Roulette"
            active={primaryFilter == "Roulette"}
            label="Roulette"
          />
        </div>
      </section>

      <section className="max-w-screen-xl mx-auto pb-10 md:pb-16 px-4 md:px-10 xl:px-20">
        <div>
          <div className="flex flex-col xl:flex-row mb-4 lg:mb-6 gap-4 relative">
            <Search
              value={searchTerms}
              onChange={e => setSearchTerms(e.target.value)}
              placeholder="Search"
              className="flex-1"
              onClear={() => setSearchTerms("")}
            />

            <div className="flex gap-4">
              <div ref={filterContainer} className="flex-1 relative">
                <button
                  className="xl:w-[270px] flex w-full items-center py-3 px-6 gap-1 rounded-md border border-gray-30 justify-between"
                  onClick={_ => toggleFilters()}
                >
                  <span className="text-sm text-font-1">Filters</span>
                  <Filter width={14} />
                </button>

                {filtersOpened && (
                  <div className="fixed bottom-0 top-0 left-0 right-0 md:absolute md:bottom-auto md:left-auto md:top-20 md:right-0 bg-white z-50 shadow-card p-6 md:rounded-2xl">
                    <div className="mb-4 flex justify-end">
                      <button onClick={closeFilters}>
                        <Close width={24} />
                      </button>
                    </div>
                    <form onSubmit={onSubmit}>
                      <div className="flex flex-col md:flex-row gap-8 mb-8">
                        <Select
                          label="Payment Methods"
                          name="payment"
                          value={payment}
                          onChange={e => setPayment(e.target.value)}
                          className="md:w-80"
                        >
                          <option value="all">Select Payment Method</option>
                          {paymentMethods.map((method, index) => {
                            return (
                              <option key={index} value={method}>
                                {method}
                              </option>
                            )
                          })}
                        </Select>
                      </div>

                      <div className="flex flex-col md:flex-row gap-8 mb-8">
                        <Select
                          label="Game Providers"
                          name="provider"
                          value={provider}
                          onChange={e => setProvider(e.target.value)}
                          className="md:w-80"
                        >
                          <option value="all">Select Provider</option>
                          {providers.map(
                            (provider: Provider, index: number) => {
                              return (
                                <option key={index} value={provider.name}>
                                  {provider.name}
                                </option>
                              )
                            }
                          )}
                        </Select>
                      </div>

                      <div className="flex flex-1 gap-10 mb-8">
                        <div>
                          <div className="mb-3 text-lg font-medium text-font-1">
                            Ratings
                          </div>
                          <div className="flex gap-4 relative">
                            {ratingsOptions.map((option, index) => {
                              return (
                                <div
                                  key={index}
                                  className="flex items-center gap-2"
                                >
                                  <input
                                    type="radio"
                                    id={option.toString()}
                                    name="rating"
                                    value={option}
                                    className="cursor-pointer accent-blue-600 w-4 h-4"
                                    onChange={e =>
                                      setRating(parseInt(e.target.value))
                                    }
                                    checked={rating == option}
                                  />
                                  <label
                                    htmlFor={option.toString()}
                                  >{`> ${option} Stars`}</label>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="text-center">
                        <input
                          type="submit"
                          value="Add Filters"
                          className="cursor-pointer inline-block py-4 px-12 text-base leading-none text-center lg:text-lg rounded-lg bg-blue-600 text-white hover:bg-blue-700"
                        />
                      </div>
                    </form>
                  </div>
                )}
              </div>

              {/* <Select
                name="sort"
                value={sort}
                onChange={e => setGame(e.target.value)}
                className="xl:shrink-0 xl:w-[270px] flex-1"
              >
                <option value="all">Sort</option>
              </Select> */}
            </div>
          </div>

          <div className="cf">
            {filter.payment && filter.payment !== "all" && (
              <ActiveFilter
                onClick={() => {
                  setFilter({
                    ...filter,
                    payment: "all",
                  })
                }}
                label={filter.payment}
              />
            )}

            {filter.provider && filter.provider !== "all" && (
              <ActiveFilter
                onClick={() => {
                  setFilter({
                    ...filter,
                    provider: "all",
                  })
                }}
                label={filter.provider}
              />
            )}

            {filter.rating > 0 && (
              <ActiveFilter
                onClick={() => {
                  setFilter({
                    ...filter,
                    rating: 0,
                  })
                }}
                label={`> ${filter.rating} Stars`}
              />
            )}
          </div>

          <h2 className="mt-4 mb-12 lg:mb-14 text-base text-font-3 font-medium">
            {casinos.length == allContentfulCasino.nodes.length ? (
              <React.Fragment>
                <span>All Casinos</span>
                <span className="ml-1">{`(${allContentfulCasino.nodes.length})`}</span>
              </React.Fragment>
            ) : (
              `${casinos.length} ${
                casinos.length > 1 ? `Casinos` : `Casino`
              } Found`
            )}
          </h2>
        </div>

        <div>
          {casinos.map((casino: Casino) => {
            return (
              <div key={casino.id} className="relative">
                <CasinoCard casino={casino} className="mb-10" />
              </div>
            )
          })}
        </div>
      </section>
    </div>
  )
}

export default CasinoList
